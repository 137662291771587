import React from 'react'
import PropTypes from 'prop-types'

const Instagram = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 30"
  >
    <g fill={fill} fillRule="evenodd">
      <path d="M14.995.01c-4.072 0-4.583.017-6.182.09-1.596.072-2.686.326-3.64.697a7.35 7.35 0 0 0-2.656 1.73 7.35 7.35 0 0 0-1.73 2.655c-.37.954-.624 2.044-.697 3.64-.073 1.6-.09 2.11-.09 6.183 0 4.072.017 4.583.09 6.182.073 1.596.327 2.686.697 3.64a7.35 7.35 0 0 0 1.73 2.656 7.351 7.351 0 0 0 2.656 1.73c.954.37 2.044.624 3.64.697 1.6.073 2.11.09 6.182.09 4.073 0 4.583-.017 6.183-.09 1.596-.073 2.686-.327 3.64-.697a7.351 7.351 0 0 0 2.656-1.73 7.351 7.351 0 0 0 1.73-2.656c.37-.954.623-2.044.696-3.64.073-1.6.09-2.11.09-6.182 0-4.073-.017-4.583-.09-6.183-.072-1.596-.326-2.686-.697-3.64a7.351 7.351 0 0 0-1.73-2.656 7.35 7.35 0 0 0-2.655-1.73c-.954-.37-2.044-.624-3.64-.696-1.6-.073-2.11-.09-6.183-.09zm0 2.701c4.004 0 4.478.016 6.06.088 1.462.066 2.256.31 2.784.516.7.272 1.2.597 1.724 1.122.525.524.85 1.024 1.122 1.724.205.528.45 1.322.516 2.784.072 1.582.088 2.056.088 6.06s-.016 4.478-.088 6.06c-.066 1.461-.31 2.255-.516 2.784-.272.7-.597 1.199-1.122 1.724a4.645 4.645 0 0 1-1.724 1.121c-.528.206-1.322.45-2.784.517-1.581.072-2.056.087-6.06.087s-4.478-.015-6.06-.087c-1.461-.067-2.255-.311-2.784-.517-.7-.272-1.199-.597-1.724-1.121a4.646 4.646 0 0 1-1.121-1.724c-.206-.529-.45-1.323-.517-2.785-.072-1.581-.087-2.055-.087-6.06 0-4.003.015-4.477.087-6.059.067-1.462.311-2.256.517-2.784.272-.7.597-1.2 1.121-1.724a4.645 4.645 0 0 1 1.724-1.122c.529-.205 1.323-.45 2.785-.516 1.581-.072 2.055-.088 6.06-.088z" />
      <path d="M14.559 19.427a4.868 4.868 0 1 1 0-9.737 4.868 4.868 0 0 1 0 9.737zm0-12.368a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15zM24.706 7.059a1.765 1.765 0 1 1-3.53 0 1.765 1.765 0 0 1 3.53 0" />
    </g>
  </svg>
)

Instagram.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
}

Instagram.defaultProps = {
  width: 30,
  height: 30,
  fill: '#2CDA9D',
}

export default Instagram
